import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Link } from 'gatsby';
import Layout from '../components/base/layout';
import Seo from '../components/base/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title={`404: Not found | ${process.env.TITLE}`} />
    <div className="">
      <div className="py-10 bg-secondary px-8 text-center border-b-2 border-gray-400">
        {/* <img className="w-48 my-6 mx-auto" src="img/hw-logo.png" alt="hw-logo" /> */}
        <a href={process.env.GATSBY_LOGO_LINK} target="_blank" rel="noreferrer">
          <StaticImage
            src="../images/hw-logo.png"
            alt="hw-logo"
            placeholder="blurred"
            layout="fixed"
            width={150}
            height={150}
            className="w-48 my-2 mx-auto"
          />
        </a>

        <div className="my-2 font-bold text-2xl">404: Not Found</div>
        <div className="my-2">
          You just hit a route that doesn&#39;t exist... the sadness.
        </div>

        <Link to="/">
          <button
            className="bg-primary text-white font-bold py-2 px-4 rounded-2xl mb-2"
            type="button"
          >
            Go to Homepage
          </button>
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
